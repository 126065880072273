/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import LCFQheader from "../../LCFQheader";
import Rypxradio from "../../../common/zzjg/Rypxradio";
import { RxUtil } from "@/assets/util.js";
import { format } from "@/assets/app.js";
export default {
  name: "lcfqNljd",
  components: {
    LCFQheader,
    Rypxradio
  },
  data() {
    return {
      oneft: "/index",
      headTit: "新建申请",
      tit: "人员监督/能力监控计划",
      flagBs: "人员监督/能力监控计划",
      showSjrq: false,
      showListpage: false,
      minDate: new Date(),
      currentDate: new Date(),
      //userSelect
      single: false,
      allowSelect: true,
      showUserDialog: false,
      showGroupDialog: false,
      nodeGroupVm: {},
      userID: "",
      solId: "",
      boDefId: "",
      formKey: "",
      data: {
        bm: "",
        jdy: "",
        jddx: '',
        jhbh: "",
        jhmc: "",
        rq: "",
        jdlb: "",
        jdxmss: "",
        bz: ""
      }
    };
  },
  created() {
    this.initBySolInstId();
    this.cgORxj();
  },
  methods: {
    initBySolInstId() {
      let newDate = new Date();
      let instId = this.$route.params.instId;
      let solId = this.$route.params.solId;
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId;
      this.$ajax.post(url).then(res => {
        this.solId = res.data.bpmSolution.solId;
        this.boDefId = res.data.formModels[0].boDefId;
        this.formKey = res.data.formModels[0].formKey;
        this.data.sqbh = res.data.formModels[0].jsonData.sqbh;
        if (instId == 0) {
          let bm = res.data.formModels[0].jsonData.bm;
          let bm_name = res.data.formModels[0].jsonData.bm_name;
          let bmObj = [{
            id: bm,
            text: bm_name
          }];
          this.data.bm = JSON.stringify(bmObj);
          let jdy = res.data.formModels[0].jsonData.jdy;
          let jdy_name = res.data.formModels[0].jsonData.jdy_name;
          let jdyObj = [{
            id: jdy,
            text: jdy_name
          }];
          this.data.jdy = JSON.stringify(jdyObj);
          let jddx = res.data.formModels[0].jsonData.jddx;
          let jddx_name = res.data.formModels[0].jsonData.jddx_name;
          let jddxObj = [{
            id: jddx,
            text: jddx_name
          }];
          this.data.jddx = JSON.stringify(jddxObj);
        }
      });
    },
    async cgORxj() {
      let instId = this.$route.params.instId;
      if (instId != 0) {
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";
        /**测试接口**/
        var user_No = RxUtil.getCache("userNo"); //这里要重新储存
        console.log(user_No);
        var url2 = _baseUrl + "/mobile/bpm/getFormDataByInstId.do?instId=" + instId + "&userAccount=" + user_No;
        var this_ = this;
        this.$ajax.post(url2).then(response => {
          var resData = response.data.data.bos[0].data;
          console.log("草稿数据返填=", resData);
          this_.data.jhbh = resData.jhbh;
          this_.data.jhmc = resData.jhmc;
          this_.data.rq = resData.rq;
          this_.data.jdlb = resData.jdlb;
          this_.data.jdxmss = resData.jdxmss;
          this_.data.bz = resData.bz;
          let bm = resData.bm;
          let bm_name = resData.bm_name;
          let bmObj = [{
            id: bm,
            text: bm_name
          }];
          this_.data.bm = JSON.stringify(bmObj);
          let jdy = resData.jdy;
          let jdy_name = resData.jdy_name;
          let jdyObj = [{
            id: jdy,
            text: jdy_name
          }];
          this_.data.jdy = JSON.stringify(jdyObj);
          let jddx = resData.jddx;
          let jddx_name = resData.jddx_name;
          let jddxObj = [{
            id: jddx,
            text: jddx_name
          }];
          this_.data.jddx = JSON.stringify(jddxObj);
        });
        /*****/
      } else {}
    },
    onConfirmSjrq(timestamp) {
      this.data.rq = format(timestamp);
      this.showSjrq = false;
    },
    //选代理人
    selectUserDialog(vm) {
      this.showUserDialog = true;
      this.$refs.sysUser.search();
      // 设置选择用户。
      this.$refs.sysUser.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    selectUser(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.userId;
        o.text = obj.fullname;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeUserVm.setValue(json);
      this.showUserDialog = false;
    },
    //科室
    selectGroupDialog(vm) {
      this.showGroupDialog = true;
      this.$refs.sysGroup.type = "group";
      this.$refs.sysGroup.search();
      // 设置选择用户。
      this.$refs.sysGroup.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeGroupVm = vm;
    },
    selectGroup(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        var test = {};
        o.id = obj.groupId;
        o.text = obj.name;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeGroupVm.setValue(json);
      this.showGroupDialog = false;
    },
    showList() {
      this.showListpage = true;
    },
    tdObj(item) {
      this.data.jhbh = item.F_JHBH;
      this.data.jhmc = item.F_JHMC;
      this.showListpage = false;
    },
    datas() {
      let bm = JSON.parse(this.data.bm);
      let jdy = JSON.parse(this.data.jdy);
      let jddx = JSON.parse(this.data.jddx);
      var jsonDataObj = {
        bos: [{
          boDefId: this.boDefId,
          formKey: this.formKey,
          data: {
            jhbh: this.data.jhbh,
            jhmc: this.data.jhmc,
            rq: this.data.rq,
            bm: bm[0].id,
            bm_name: bm[0].text,
            jdlb: this.data.jdlb,
            jdy: jdy[0].id,
            jdy_name: jdy[0].text,
            jddx: jddx[0].id,
            jddx_name: jddx[0].text,
            jdxmss: this.data.jdxmss,
            bz: this.data.bz
          }
        }]
      };
      var jsonData = JSON.stringify(jsonDataObj);
      var params = new FormData();
      params.append("solId", this.solId);
      params.append("bpmInstId", "");
      params.append("jsonData", jsonData);
      return params;
    },
    //字段检查
    iN(str) {
      if (str == '' || str == null || typeof str == 'undefined') {
        return true;
      }
      return false;
    },
    //整体判断
    inspect() {
      if (this.iN(this.data.jhbh) || this.iN(this.data.jhmc) || this.iN(this.data.bm) || this.iN(this.data.jdy) || this.iN(this.data.jddx)) {
        return true;
      }
      return false;
    },
    onSubmit() {
      if (this.inspect()) {
        this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        return;
      }
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      this.$dialog.loading.open('正在启动流程,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        console.log(res);
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("启动成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败或信息未填完整！", "1800");
        }
      });
    },
    onPreservation() {
      if (this.inspect()) {
        this.errorToast("保存失败！请检查信息是否填写完整", "1800");
        return;
      }
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("保存失败或信息未填完整！", "1800");
        }
      });
    },
    onLct() {
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};